<template>
  <section id="apps" class="py-2">
    <v-container>
      <v-dialog v-model="dialog">
        <v-card>
          <coreMobileAppsComponent></coreMobileAppsComponent>
        </v-card>
      </v-dialog>
      <h2 class="font-weight-bold mb-3 text-h4 text-center" style="text-transform: uppercase !important">Lego Deals</h2>
      <v-list subheader>
        <v-list-item v-for="(item, i) in legoSets" :key="i" @click="viewItem(item)">
          <div v-if="i === 1">
            <ins
              class="adsbygoogle"
              style="display: block"
              data-ad-format="fluid"
              data-ad-layout-key="-h1+t-1g-ff+xf"
              data-ad-client="ca-pub-4303735837125252"
              data-ad-slot="4327150483"
              data-full-width-responsive="true"
            >
            </ins>
          </div>
          <div style="display: flex" class="mb-1">
            <v-img :alt="`${item.setName}`" :src="item.set.thumbnailURL" style="flex: 0 0 100px" class="mr-2"></v-img>
            <div>
              <v-list-item-title style="font-weight: bold; white-space: break-spaces">{{ item.setNumber }}-1 {{ item.setName }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.set.year }} {{ item.set.pieces }} pcs ${{ item.set.USRetailPrice }}</v-list-item-subtitle>
              <v-list-item-subtitle>${{ item.price }} at {{ item.storeName }}</v-list-item-subtitle>
            </div>
          </div>
          <v-divider></v-divider>
        </v-list-item>
      </v-list>
    </v-container>
  </section>
</template>

<script>
import coreMobileAppsComponent from '../../_core/components/core.mobileApps.component.vue';

export default {
  name: 'homelegoSetsComponent',
  props: ['legoSets', 'isLoading'],
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    // this.adsenseAddLoad();
  },
  methods: {
    viewItem(item) {
      //      console.log(item);
      //      this.dialog = true;
      this.$router.push(`/sets?id=${item.setId}`);
    },
    adsenseAddLoad() {
      const inlineScript = document.createElement('script');
      inlineScript.type = 'text/javascript';
      inlineScript.text = '(adsbygoogle = window.adsbygoogle || []).push({});';
      document.getElementsByTagName('body')[0].appendChild(inlineScript);
    },
  },
  components: { coreMobileAppsComponent },
};
</script>

<template>
  <v-app id="app" :theme="theme">
    <metainfo>
      <template v-slot:title="{ content }">{{ `${content} | ${this.$route.params.name || this.$route.name}` }} </template>
    </metainfo>

    <v-snackbar
      v-if="config.vuetify.theme.snackbar.status"
      v-model="snackbar.status"
      :top="true"
      :right="true"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:actions>
        <v-btn @click="snackbar.status = false" icon>
          <v-icon icon="fa-solid fa-circle-xmark"></v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <waosHeader />

    <waosNav v-if="!config.vuetify.theme.navigation.displayIfLogged || isLoggedIn" />

    <v-main class="pb-0" :style="{ background: config.vuetify.theme.themes[theme].colors.background }">
      <router-view />

      <waosFooter
        v-bind:links="config.home.links"
        v-bind:custom="{
          section: { background: config.vuetify.theme.themes[theme].colors.surface, 'min-width': '100%' },
        }"
      />
    </v-main>
  </v-app>
</template>

<script>
/**
 * Module dependencies.
 */
import { mapGetters } from 'vuex';
import waosHeader from '../_core/components/core.header.component.vue';
import waosNav from '../_core/components/core.nav.component.vue';
import waosFooter from '../_core/components/core.footer.component.vue';

/**
 * Export default
 */
export default {
  name: 'App',
  data() {
    return {
      snackbar: {
        status: false,
        color: 'error',
        timeout: 4000,
        text: 'toto',
      },
    };
  },
  metaInfo() {
    return {
      title: this.config.app.title,
      description: this.config.app.description,
      meta: [
        { name: 'keywords', content: this.config.app.keywords },
        { name: 'author', content: this.config.app.author },
      ],
    };
  },
  components: {
    waosHeader,
    waosNav,
    waosFooter,
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'theme']),
  },
};
</script>

<style>
.v-application header a,
.v-application nav a {
  text-decoration: none !important;
  color: rgba(var(--v-theme-onPrimary), 1) !important;
}
.v-application main a:not(.v-btn) {
  text-decoration: none !important;
  font-weight: 400;
  color: rgba(var(--v-theme-secondary), 1) !important;
}
.v-card {
  border: none !important;
}
.loading {
  text-align: center;
  margin: 40px;
}
</style>

<template>
  <div>
    <div v-if="isLoadingLegoSet">
      <div class="loading">
        <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <div v-if="legoSet !== null">
        <legoSetComponent v-bind:legoSet="legoSet"></legoSetComponent>
      </div>
      <div v-else>No sets</div>
    </div>
  </div>
</template>

<script>
/**
 * Module dependencies.
 */
import { mapGetters } from 'vuex';
import legoSetComponent from '../components/set.component.vue';

/**
 * Export default
 */
export default {
  data() {
    return {
      // vue
      setId: this.$route.params.id || this.$route.query.id,
    };
  },
  components: {
    legoSetComponent,
  },
  computed: {
    ...mapGetters(['legoSet', 'legoSetPrices', 'isLoadingLegoSet']),
  },
  created() {
    this.$store.dispatch('getLegoSet', { setId: this.setId });
    //    this.$store.dispatch('getLegoSetPrices', { setId: this.setId });
  },
};
</script>

<style>
.centered-input >>> input {
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <section id="apps" class="py-2">
    <v-container>
      <h2 class="font-weight-bold mb-3 pb-1 text-h5 text-center" style="text-transform: uppercase !important">Download Mobile App for more</h2>
      <v-row>
        <v-col cols="12" md="6" data-aos="fade-up">
          <!-- eslint-disable-next-line -->
          <a href="https://apps.apple.com/us/app/bestbrickprice/id988676154" target="_blank">
            <v-img :src="require('@/assets/images/ios_app_store.png')" class="mb-1 rounded-xl" max-width="100%" cover></v-img>
            <h3 class="mb-4 text-center">IOS App Store</h3>
            <div class="subtitle-1 text-medium-emphasis text-onSurface"></div>
          </a>
        </v-col>
        <v-col cols="12" md="6" data-aos="fade-up">
          <!-- eslint-disable-next-line -->
          <a href="https://play.google.com/store/apps/details?id=com.bestbrickprice.LegoApp" target="_blank">
            <v-img :src="require('@/assets/images/play_store.png')" class="mb-1 rounded-xl" max-width="100%" cover></v-img>
            <h3 class="mb-4 text-center">Android Play Store</h3>
            <div class="subtitle-1 text-medium-emphasis text-onSurface"></div>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
/**
 * Export default
 */
export default {
  name: 'coreMobileAppsComponent',
};
</script>

/**
 * don't edit this file /!\
 * it' a generated one
 * edit in defaults/*, cf readme
 */
/* eslint-disable */
module.exports = {
  app: {
    title: 'BestBrickPrice',
    status: 'Prod',
    logo: 'logo.png',
    subtitle: 'welcome to BestBrickPrice.',
    description: 'BestBrickPrice',
    keywords: 'BestBrickPrice lego',
    author: 'BestBrickPrice@gmail.com',
  },
  port: 8080,
  api: {
    protocol: 'https',
    host: 'bestbrickprice.com',
    port: '3000',
    base: 'api',
    endPoints: {
      home: 'home',
      auth: 'auth',
      users: 'users',
      tasks: 'tasks',
    },
    url: 'https://bestbrickprice.com/api',
  },
  cookie: {
    prefix: 'bbp',
  },
  oAuth: {
    google: true,
    apple: true,
  },
  whitelists: {
    users: {
      roles: [
        'user',
        'admin',
      ],
    },
  },
  analytics: {
    matomo: {},
  },
  vuetify: {
    theme: {
      dark: 'auto',
      flat: true,
      snackbar: {
        status: true,
        methods: [
          'post',
          'put',
        ],
        sucessColor: 'success',
        errorColor: 'error',
      },
      navigation: {
        ifLogged: false,
        selectBorder: 'secondary',
        drawer: {
          model: false,
          floating: true,
          expand: true,
          rail: true,
        },
      },
      themes: {
        light: {
          colors: {
            primary: '#34495e',
            secondary: '#EE5A24',
            background: '#f3f3f6',
            surface: '#ffffff',
            error: '#e01f26',
            success: '#119178',
            onPrimary: '#ffffff',
            onSecondary: '#FFFFFF',
            onBackground: '#1d1d1f',
            onSurface: '#1d1d1f',
            onError: '#FFFFFF',
            onSuccess: '#FFFFFF',
          },
        },
        dark: {
          colors: {
            primary: '#34495e',
            secondary: '#e67e22',
            background: '#1F1F1F',
            surface: '#282A2E',
            error: '#CF6679',
            success: '#119178',
            onPrimary: '#FFFFFF',
            onSecondary: '#FFFFFF',
            onBackground: '#FFFFFF',
            onSurface: '#FEFEFE',
            onError: '#000000',
            onSuccess: '#000000',
          },
        },
      },
      options: {
        customProperties: true,
      },
    },
    icons: {
      defaultSet: 'fa',
    },
  },
  header: {
    socials: [
      {
        icon: 'fa-brands fa-twitter',
        label: 'twitter',
        url: 'https://twitter.com/bestbrickprice',
      },
    ],
  },
  home: {
    temporalBackground: 'https://blog.weareopensource.me/content/images/size/w1600/2020/11',
    abouts: [
      {
        title: 'About Us',
        text: '[WAOS](https://blog.weareopensource.me/us/)\'s goal is to simplify the **start** of new **tech projects** / **startups**.           As we know It\'s must be **fast**, **efficient** while **avoiding the refactor** afterward.           So whether through the creation of [Open-Source](https://github.com/weareopensource) **stacks** / **tools** or **articles**,           we **share our knowledge** around this subject.',
        image: 'https://avatars3.githubusercontent.com/u/8588816?s=200&v=4',
        button: 'Github',
        link: 'https://github.com/weareopensource',
      },
    ],
    features: {
      title: 'Mobile App',
      data: [
        {
          icon: 'fa-solid fa-users',
          image: 'https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/29/0b/a3/290ba3a5-5a2c-c7fb-7f54-b526400bfcad/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp',
          title: 'IOS App Store',
          text: '**Lorem** ipsum dolor sit amet consectetur adipisicing elit.             Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora!             Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
        },
        {
          icon: 'fa-solid fa-cloud-arrow-up',
          title: 'Frequent Updates',
          text: '**Sed** ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit.             Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit.',
        },
      ],
    },
    slideshow: {
      title: 'Demos',
      data: [
        {
          img: 'https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          icon: 'fa-solid fa-users',
          title: 'Vibrant Community',
          text: '**Lorem** ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint ...',
        },
        {
          img: 'https://images.unsplash.com/photo-1523712999610-f77fbcfc3843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          icon: 'fa-solid fa-cloud-arrow-up',
          title: 'Frequent Updates',
        },
      ],
    },
    stats: {
      background: 'https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
      data: [
        {
          value: '0',
          title: 'Tasks',
        },
        {
          value: '0',
          title: 'Releases',
        },
        {
          value: '0',
          title: 'Users',
        },
        {
          value: '50m',
          title: 'Total Downloads',
        },
      ],
    },
    blog: {
      title: 'Mobile Apps',
      url: 'https://blog.weareopensource.me',
      subscribe: 'https://blog.weareopensource.me/#subscribe',
      key: '0415f48774e7c49c713204f787',
    },
    contact: {
      title: 'Contact Us',
      mail: 'mailto:pierre@weareopensource.me',
    },
  },
};

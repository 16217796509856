/**
 * Module dependencies.
 */
import updates from '../views/updates.view.vue';

/**
 * Router configuration
 */

export default [
  {
    path: '/updates',
    name: 'Stock Info',
    component: updates,
    meta: {
      icon: 'fa-solid fa-arrow-trend-up',
      display: true, // hide from drawer any time
      footer: true, // display footer
    },
  },
];

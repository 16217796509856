/* eslint-disable no-bitwise */
/**
 * Module dependencies.
 */
/**
 * Getters: get state
 */

// import { query, orderBy, limit, getDocs } from 'firebase/firestore';

// import { updatesRef } from '../../_app/firebase';

const getters = {
  isLoadingUpdates: (state) => state.isLoadingUpdates,
  storeUpdates: (state) => state.storeUpdates,
};

const ALERT_TYPE_SET_PRICE_CHANGED = 1;
const ALERT_TYPE_SET_AVAILABILITY_CHANGED = 2;
const ALERT_TYPE_SET_TAGS_CHANGED = 4;
const ALERT_TYPE_SET_RETIRED = 8;
const ALERT_TYPE_SET_RELEASED = 16;

function processItem(item) {
  if (item.oldInfo) {
    item.oldInfo = JSON.parse(item.oldInfo);
  }
  if (item.newInfo) {
    item.newInfo = JSON.parse(item.newInfo);
  }
  const result = {
    ...item,
    changes: [],
  };

  if (item.changed & ALERT_TYPE_SET_RELEASED) {
    const change = {
      type: ALERT_TYPE_SET_RELEASED,
      subject: `The set was released.`,
    };
    result.changes.push(change);
  }
  if (item.changed & ALERT_TYPE_SET_RETIRED) {
    const change = {
      type: ALERT_TYPE_SET_RETIRED,
      subject: `The set was retired.`,
    };
    result.changes.push(change);
  }
  if (item.oldInfo) {
    if (item.changed & ALERT_TYPE_SET_PRICE_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_PRICE_CHANGED,
        subject: `The price`,
        oldValue: item.oldInfo.price,
        newValue: item.newInfo.price,
      };
      result.changes.push(change);
    }
    if (item.changed & ALERT_TYPE_SET_AVAILABILITY_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_AVAILABILITY_CHANGED,
        subject: `The availablity`,
        oldValue: item.oldInfo.status,
        newValue: item.newInfo.status,
      };
      result.changes.push(change);
    }
    if (item.changed & ALERT_TYPE_SET_TAGS_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_AVAILABILITY_CHANGED,
        subject: `The tag`,
        oldValue: item.oldInfo.tags,
        newValue: item.newInfo.tags,
      };
      result.changes.push(change);
    }
  }
  return result;
}

/*
async function processItem0(app, doc) {
  const item = doc.data();
  const result = {
    id: doc.id,
    setId: item.setId,
    timestamp: item.timestamp.toDate(),
    newInfo: item.newInfo,
    set: null,
    changes: [],
  };

  if (item.changed & ALERT_TYPE_SET_RELEASED) {
    const change = {
      type: ALERT_TYPE_SET_RELEASED,
      subject: `The set was released.`,
    };
    result.changes.push(change);
  }
  if (item.changed & ALERT_TYPE_SET_RETIRED) {
    const change = {
      type: ALERT_TYPE_SET_RETIRED,
      subject: `The set was retired.`,
    };
    result.changes.push(change);
  }
  if (item.oldInfo) {
    if (item.changed & ALERT_TYPE_SET_PRICE_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_PRICE_CHANGED,
        subject: `The price`,
        oldValue: item.oldInfo.price,
        newValue: item.newInfo.price,
      };
      result.changes.push(change);
    }
    if (item.changed & ALERT_TYPE_SET_AVAILABILITY_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_AVAILABILITY_CHANGED,
        subject: `The availablity`,
        oldValue: item.oldInfo.status,
        newValue: item.newInfo.status,
      };
      result.changes.push(change);
    }
    if (item.changed & ALERT_TYPE_SET_TAGS_CHANGED) {
      const change = {
        type: ALERT_TYPE_SET_AVAILABILITY_CHANGED,
        subject: `The tag`,
        oldValue: item.oldInfo.tags,
        newValue: item.newInfo.tags,
      };
      result.changes.push(change);
    }
  }
  result.set = (await app.config.globalProperties.axios.get(`/api/BrickSets/GetSetById/${result.setId}?includePrices=1`)).data.Set;

  return result;
}
*/

/**
 * Actions
 */
const actions = (app) => {
  return {
    getStoreUpdates: async ({ commit }) => {
      try {
        commit('isLoadingUpdates_set', true);

        const items = (await app.config.globalProperties.axios.get(`/api/StoreUpdate`)).data;
        const updates = [];
        items.forEach((item) => {
          updates.push(processItem(item));
        });

        /*        const q = query(updatesRef, limit(5), orderBy('timestamp', 'desc'));
        const querySnap = await getDocs(q);
        const items = [];
        const docs = [];
        querySnap.forEach((doc) => {
          docs.push(doc);
        });
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < docs.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          items.push(await processItem(app, docs[i]));
        }
*/
        commit('storeupdates_set', updates);
      } catch (err) {
        console.error('Error getting updates: ', err);
        commit('error', err);
      }
      commit('isLoadingUpdates_set', false);
    },
  };
};

/**
 * Mutation: change state in a Vuex store is by committing a mutation
 */
const mutations = {
  error(err) {
    console.log(err);
  },
  storeupdates_set(state, data) {
    state.storeUpdates = data;
  },
  isLoadingUpdates_set(state, data) {
    state.isLoadingUpdates = data;
  },
};

/**
 * State
 */
const state = () => {
  return {
    storeUpdates: [],
  };
};

/**
 * Export default
 */
export default (app) => {
  return {
    state: state(),
    getters,
    actions: actions(app),
    mutations,
  };
};

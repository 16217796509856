<template>
  <section id="apps" class="py-2">
    <v-container>
      <h2 class="font-weight-bold mb-3 text-h4 text-center" style="text-transform: uppercase !important">Stock Updates</h2>
      <div v-if="isLoadingUpdates">
        <div class="loading">
          <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </div>
      </div>
      <div v-else>
        <v-list subheader>
          <v-list-item v-for="(item, i) in storeUpdates" :key="i" @click="viewItem(item)">
            <div v-if="i === 1">
              <ins
                class="adsbygoogle"
                style="display: block"
                data-ad-format="fluid"
                data-ad-layout-key="-h1+t-1g-ff+xf"
                data-ad-client="ca-pub-4303735837125252"
                data-ad-slot="4327150483"
                data-full-width-responsive="true"
              >
              </ins>
            </div>
            <div style="display: flex" class="mb-1" v-if="item.set">
              <v-img :alt="`${item.set.name}`" :src="item.set.thumbnailURL" style="flex: 0 0 100px" class="mr-2"></v-img>
              <div>
                <v-list-item-title style="font-weight: bold; white-space: break-spaces">{{ item.set.number }}-{{ item.set.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.set.year }} {{ item.set.pieces }} pcs ${{ item.set.USRetailPrice }}</v-list-item-subtitle>
                <p class="item-description">
                  [<time>{{ dateTime(item.timestamp) }}</time
                  >]
                  <span v-for="(change, i) in item.changes" :key="i">
                    <span v-if="change.oldValue && change.newValue">
                      <span class="item-changed-subject">{{ change.subject }}</span>
                      changed
                      <span v-if="change.oldValue"
                        >from
                        <span class="item-changed-old-value">[{{ change.oldValue }}] </span>
                      </span>
                      to
                      <span class="item-changed-new-value">[{{ change.newValue }}]</span>.
                    </span>
                    <span v-if="change.oldValue && !change.newValue">
                      <span class="item-change-subject">{{ change.subject }} </span>
                      <span class="item-changed-old-value">[{{ change.oldValue }}] </span>
                      was removed.
                    </span>
                    <span v-if="!change.oldValue && change.newValue">
                      <span class="item-changed-subject">{{ change.subject }} </span>
                      <span class="item-changed-new-value">[{{ change.newValue }}]</span>
                      was added.
                    </span>
                    <span v-if="!change.oldValue && !change.newValue">
                      <span class="item-changed-subject">{{ change.subject }} </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <v-divider></v-divider>
          </v-list-item>
        </v-list>
      </div>
    </v-container>
  </section>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'storeUpdates',
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    // this.adsenseAddLoad();
  },
  methods: {
    dateTime(value) {
      return moment(value).fromNow();
    },
    viewItem(item) {
      //      console.log(item);
      //      this.dialog = true;
      this.$router.push(`/sets?id=${item.setid}`);
    },
  },
  created() {
    this.$store.dispatch('getStoreUpdates');
  },
  computed: {
    ...mapGetters(['storeUpdates', 'isLoadingUpdates']),
  },
  components: {},
};
</script>

<style>
.item-changed-new-value {
  color: blue;
}
</style>

/**
 * Module dependencies.
 */
/**
 * Getters: get state
 */
const getters = {
  isLoading: (state) => state.isLoading,
  legoSets: (state) => state.legoSets,
};

/**
 * Actions
 */
const actions = (app) => {
  const api = app.config.globalProperties.config.api.url || 'api';
  return {
    getLegoSets: async ({ commit }) => {
      try {
        commit('isLoading_set', true);
        const sets = (await app.config.globalProperties.axios.get(`${api}/BrickSets/GetRecentPriceChanges?limit=15`)).data;
        commit('legosets_set', sets);
      } catch (err) {
        commit('error', err);
      }
      commit('isLoading_set', false);
    },
  };
};

/**
 * Mutation: change state in a Vuex store is by committing a mutation
 */
const mutations = {
  error(err) {
    console.log(err);
  },
  isLoading_set(state, data) {
    state.isLoading = data;
  },
  legosets_set(state, data) {
    state.legoSets = data;
  },
};

/**
 * State
 */
const state = () => {
  return {
    legoSets: [],
  };
};

/**
 * Export default
 */
export default (app) => {
  return {
    state: state(),
    getters,
    actions: actions(app),
    mutations,
  };
};

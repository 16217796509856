<template>
  <div>
    <div v-if="legoSet.name">
      <v-card class="ma-2">
        <v-card-title style="white-space: break-spaces"> {{ legoSet.number }}-{{ legoSet.numberVariant }} {{ legoSet.name }} </v-card-title>
        <v-img :alt="`${legoSet.name}`" :src="legoSet.imageURL" max-width="800" class="ma-1"></v-img>
        <div>
          <v-card-subtitle class="my-2 text-subtitle-1">Theme: {{ legoSet.theme }}</v-card-subtitle>
          <v-card-subtitle class="my-2 text-subtitle-1">Year: {{ legoSet.year }} Pieces: {{ legoSet.pieces }}</v-card-subtitle>
          <v-card-subtitle class="my-2 text-subtitle-1">Retail Price: ${{ legoSet.USRetailPrice }}</v-card-subtitle>
        </div>
      </v-card>
      <v-card class="ma-2">
        <v-card-title>Prices:</v-card-title>
        <a
          v-for="(item, i) in legoSet.prices"
          :key="i"
          class="pa-2"
          style="display: flex; justify-content: space-between"
          :href="item.AffiliateUrl || item.Url"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div>{{ item.StoreName }}</div>
          <div>${{ item.Total }}</div>
        </a>
      </v-card>
      <!--coreMobileAppsComponent></coreMobileAppsComponent-->
    </div>
    <v-card v-else>
      <v-card-title>No set found</v-card-title>
    </v-card>
  </div>
</template>

<script>
// import coreMobileAppsComponent from '../../_core/components/core.mobileApps.component.vue';

export default {
  name: 'legoSetComponent',
  props: ['legoSet'],
  /*
  components: {
    coreMobileAppsComponent,
  },
  */
};
</script>

<template>
  <section id="apps" class="">
    <!--    <v-container>
      <h2 class="font-weight-bold mb-3 pb-8 text-h4 text-center" style="text-transform: uppercase !important">Store Updates</h2>
    </v-container>
-->
  </section>
</template>

<script>
/**
 * Export default
 */
export default {
  name: 'homeAppsComponent',
};
</script>

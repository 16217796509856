<template>
  <div>
    <div v-if="isLoading">
      <div class="loading">
        <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
      </div>
    </div>
    <div v-else>
      <homeLegoSetsComponent v-bind:legoSets="legoSets"></homeLegoSetsComponent>
      <homeUpdatesComponent></homeUpdatesComponent>
      <!--coreMobileAppsComponent></coreMobileAppsComponent-->
    </div>
  </div>
</template>

<script>
/**
 * Module dependencies.
 */
import { mapGetters } from 'vuex';
// import coreMobileAppsComponent from '../../_core/components/core.mobileApps.component.vue';
import homeLegoSetsComponent from '../components/home.sets.component.vue';
import homeUpdatesComponent from '../components/home.updates.component.vue';

/**
 * Export default
 */
export default {
  components: {
    // coreMobileAppsComponent,
    homeLegoSetsComponent,
    homeUpdatesComponent,
  },
  computed: {
    ...mapGetters(['legoSets', 'isLoading']),
  },
  created() {
    this.$store.dispatch('getLegoSets');
  },
};
</script>

<style>
.centered-input >>> input {
  text-align: center;
  font-size: 20px;
}
</style>

/**
 * Module dependencies.
 */
import { createApp } from 'vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import VueGtag from 'vue-gtag-next';
import Ads from 'vue-google-adsense';
import store from './modules/_app/app.store';
import router from './modules/_app/app.router';
import plugins from './lib/plugins';
import config from './config/index.cjs';
import App from './modules/_app/app.vue';

const app = createApp(App);

app.config.globalProperties.config = config;

app
  .use(store(app))
  .use(router(app))
  .use(createMetaManager())
  .use(metaPlugin)
  .use(plugins.aos)
  .use(plugins.axios)
  .use(plugins.images)
  .use(plugins.lodash)
  .use(plugins.markdown)
  .use(plugins.matomo)
  .use(plugins.moment)
  .use(plugins.vuetify)
  .use(plugins.gravatar)
  .use(VueGtag, {
    property: {
      id: 'UA-2825428-7',
      params: {
        send_page_view: false,
        linker: {
          domain: ['bestbrickprice.com'],
        },
      },
    },
  })
  .use(Ads.InFeedAdsense)
  // .use(Ads.Adsense)
  // .use(Ads.AutoAdsense, { adClient: 'ca-pub-4303735837125252', isNewAdsCode: true })
  .mount('#app');

/**
 * Module dependencies.
 */
import set from '../views/set.view.vue';

/**
 * Router configuration
 */

export default [
  {
    path: '/sets',
    name: 'LegoSets',
    component: set,
    meta: {
      display: false, // hide from drawer any time
      footer: true, // display footer
    },
  },
];

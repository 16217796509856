/**
 * Module dependencies.
 */
/**
 * Getters: get state
 */
const getters = {
  isLoadingLegoSet: (state) => state.isLoadingLegoSet,
  legoSet: (state) => state.legoSet,
  legoSetPrices: (state) => state.legoSetPrices,
};

/**
 * Actions
 */
const actions = (app) => {
  return {
    getLegoSet: async ({ commit }, params) => {
      try {
        if (!params.setId) {
          console.log('No setId.');
          return;
        }
        commit('isLoadingLegoSet_set', true);
        const set = (await app.config.globalProperties.axios.get(`/api/BrickSets/GetSetById/${params.setId}?includePrices=1`)).data.Set;
        commit('legoset_set', set);
        commit('isLoadingLegoSet_set', false);
      } catch (err) {
        commit('isLoadingLegoSet_set', false);
        commit('error', err);
      }
    },
    getLegoSetPrices: async ({ commit }, params) => {
      try {
        if (!params.setId) {
          console.log('No setId.');
          return;
        }
        const prices = (await app.config.globalProperties.axios.get(`/api/BrickSets/GetPrices3/${params.setId}`)).data;
        commit('legoset_prices_set', prices);
      } catch (err) {
        commit('error', err);
      }
    },
  };
};

/**
 * Mutation: change state in a Vuex store is by committing a mutation
 */
const mutations = {
  error(err) {
    console.log(err);
  },
  legoset_set(state, data) {
    state.legoSet = data;
  },
  legoset_prices_set(state, data) {
    state.legoSetPrices = data;
  },
  isLoadingLegoSet_set(state, data) {
    state.isLoadingLegoSet = data;
  },
};

/**
 * State
 */
const state = () => {
  return {
    legoSet: {},
    legoSetPrices: [],
  };
};

/**
 * Export default
 */
export default (app) => {
  return {
    state: state(),
    getters,
    actions: actions(app),
    mutations,
  };
};
